import { userAccountCheck } from '@/api/player'

// 给vee-validate提供校验規則函數
export default {
  // 用户名校验
  loginName(value) {
    if (!value) return '请输入登录账號'
    // 規則：字母开頭6-20字符之间
    // if (!/^[a-zA-Z]\w{5,19}$/.test(value)) return '字母开頭且6-20个字符'
    return true
  },
  // 用户校验且校验唯一性
  async accountApi(value) {
    if (!value) return '请输入用户名'
    if (!/^[a-zA-Z]\w{5,19}$/.test(value)) return '字母开頭且6-20个字符'
    // 服务器端校验
    const data = await userAccountCheck(value)
    if (data.result.valid) return '用户名已存在'
    return true
  },
  // 密碼校验
  password(value) {
    if (!value) return '请输入登录密碼'
    // 規則：密碼格式6-12个字符
    if (!/^\w{6,12}$/.test(value)) return '密碼6-12个字符'
    return true
  },
  // 旧密碼校验
  oldSafePass(value) {
    if (!value) return '请输入旧密碼'
    // 規則：密碼格式6-12个字符
    if (!/^\w{6,12}$/.test(value)) return '密碼6-12个字符'
    return true
  },
  // 新密碼校验
  newSafePass(value) {
    if (!value) return '请输入新密碼'
    // 規則：密碼格式6-12个字符
    if (!/^\w{6,12}$/.test(value)) return '密碼6-12个字符'
    return true
  },
  // 確認密碼校验
  confirmSafePass(value, { form }) {
    if (!value) return '请输入確認密碼'
    if (!/^\w{6,12}$/.test(value)) return '密碼6-12个字符'
    // form表單數據對象
    if (value !== form.newSafePass) return '確認密碼和新密碼不一致'
    return true
  },
  mobile(value) {
    if (!value) return '请输入手机號碼'
    // 規則：1开頭 3-9 之间  9个數字
    if (!/^1[3-9]\d{9}$/.test(value)) return '手机號格式不對'
    return true
  },
  smsCode(value) {
    if (!value) return '请输入短信验证碼'
    // 規則： 6个數字
    if (!/^\d{6}$/.test(value)) return '短信验证碼6个數字'
    return true
  },
}
